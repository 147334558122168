export interface PhotoNameToIdMap {
  [key: string]: string;
}

interface PhotoWithCaption {
  id: string;
  caption: string;
}

interface Photo {
  id: string;
}

export interface Team {
  name: string;
  description: string;
  lookingFor: string;
  spotsOpen: string;
  photoIds: string[];
}

interface ResponsiveGalleryPhoto {
  id: string;
  width: string;
  height: string;
  caption: string;
}

export interface TeamMember {
  name: string;
  role: string;
  photoId: string;
  linkedInUrl: string;
}

// map sheet name to data
export interface SheetRowStructureMap {
  "Landing Page - Group Photo": PhotoWithCaption;
  "Landing Page - Hacker Gallery": PhotoWithCaption;
  "Landing Page - Organizer Gallery": PhotoWithCaption;
  Sponsors: Photo;
  "Sponsor Page - Learn More": PhotoWithCaption;
  "About Us - Teams": Team;
  "About Us - Gallery": ResponsiveGalleryPhoto;
  "About Us - Information": PhotoWithCaption;
  "About Us - Meet the Team": TeamMember;
  "About Us - Organizer Photos": Photo;
}

// show how to turn rows into data

export function mapRowsToData<SheetName extends keyof SheetRowStructureMap>(
  sheetName: SheetName,
  rows: string[][],
  nameToIdMap: PhotoNameToIdMap
): SheetRowStructureMap[SheetName][] {
  switch (sheetName) {
    case "Landing Page - Group Photo":
    case "Landing Page - Hacker Gallery":
    case "Landing Page - Organizer Gallery":
    case "About Us - Information":
    case "Sponsor Page - Learn More":
      return rows.map((row) => {
        const [fileName, caption] = row;
        const fileId = nameToIdMap[fileName];
        checkFileIdExists(fileId, fileName);
        return {
          id: fileId,
          caption,
        } as SheetRowStructureMap[SheetName];
      });
    case "Sponsors":
    case "About Us - Organizer Photos":
      return rows.map((row) => {
        const [fileName] = row;
        const fileId = nameToIdMap[fileName];
        checkFileIdExists(fileId, fileName);
        return { id: fileId } as SheetRowStructureMap[SheetName];
      });
    case "About Us - Teams":
      return rows.map((row) => {
        const [name, description, lookingFor, spotsOpen, ...photoNames] = row;
        const photoIds = photoNames.map((photoName) => {
          const fileId = nameToIdMap[photoName];
          checkFileIdExists(fileId, photoName);
          return fileId;
        });
        return {
          name,
          description,
          lookingFor,
          photoIds,
          spotsOpen,
        } as SheetRowStructureMap[SheetName];
      });
    case "About Us - Gallery":
      return rows.map((row) => {
        const [photoName, width, height, caption] = row;
        const id = nameToIdMap[photoName];
        checkFileIdExists(id, photoName);
        return {
          id,
          width,
          height,
          caption,
        } as SheetRowStructureMap[SheetName];
      });
    case "About Us - Meet the Team":
      return rows.map((row) => {
        const [name, role, photoName, linkedInUrl] = row;
        const photoId = nameToIdMap[photoName];
        checkFileIdExists(photoId, photoName);
        return {
          name,
          role,
          photoId,
          linkedInUrl,
        } as SheetRowStructureMap[SheetName];
      });
    default:
      throw new Error("Invalid sheet name");
  }
}

// other useful stuff (but does not need to be updated)

export type MailingSiteData = {
  [SheetName in keyof SheetRowStructureMap]: SheetRowStructureMap[SheetName][];
};

export interface GalleryProps {
  getSheetData: <SheetName extends keyof MailingSiteData>(
    sheetName: SheetName,
    maxSize: number
  ) => MailingSiteData[SheetName];
}

function checkFileIdExists(fileId: string, name: string) {
  if (fileId === undefined) {
    console.error(
      `File ID ${fileId} for ${name} not found. Double check the file name in the google sheet and the google drive folder.`
    );
  }
}
