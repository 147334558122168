import React, { useMemo } from "react";
import { GalleryPhoto } from "../common/GalleryPhoto";
import {
  ContentContainer,
  PageWrapper,
  SectionContainer,
} from "../common/PageWrapper";
import { TitleSection } from "./TitleSection";
import css from "./LandingPage.module.scss";
import { GalleryProps } from "../googleSheetsTypes";
import { SponsorLogos } from "../common/SponsorLogos";
import BackgroundImage from "../static/backgrounds/landing-page-background.webp";
import SponsorBackground from "../static/backgrounds/sponsor-background.webp";
import {
  ButtonNoBackground,
  ButtonWithBackground,
  ButtonWithOutline,
} from "../common/Buttons";
import {
  Cloud5,
  Cloud6,
  Cloud7,
  Cloud8,
  Planet1Src,
  Planet2Src,
  Planet3Src,
  Planet4Src,
  Planet5Src,
} from "./svgs/svgs";
import classNames from "classnames";

export const LandingPage: React.FC<GalleryProps> = ({ getSheetData }) => {
  return (
    <PageWrapper background={`url(${BackgroundImage}`}>
      <TitleSection />
      <Spacer height={150} />
      <AboutSection />
      <Spacer height={50} />
      <ContentContainer>
        <HackerGallerySection getSheetData={getSheetData} />
      </ContentContainer>
      <SponsorSection getSheetData={getSheetData} />
      <ContentContainer>
        <BecomeAnOrganizerSection getSheetData={getSheetData} />
      </ContentContainer>
    </PageWrapper>
  );
};

const Spacer: React.FC<{ height: number }> = ({ height }) => {
  return <div style={{ height: `${height}px` }}></div>;
};

const AboutSection: React.FC = () => {
  return (
    <div className={css.aboutContainer}>
      <ContentContainer>
        <div className={css.aboutText}>
          <h2>About LA Hacks</h2>
          <div>
            <p>
              LA Hacks is a 36-hour annual event that takes place at UCLA's
              iconic Pauley Pavilion, attracting 1000+ hackers, fostering
              innovation through collaboration as participants race to build
              impactful technical projects using cutting-edge technologies.
              Organized for students, by students, this event offers unique
              opportunities for industry engagement, networking, personal and
              professional growth.
            </p>
            <p>LA Hacks 2025 will be the 13th iteration of this event!</p>
          </div>
          <a href="/about-us">
            <ButtonWithOutline>Learn More</ButtonWithOutline>
          </a>
        </div>
      </ContentContainer>
      <div className={css.planets}>
        <Planet src={Planet1Src} className={css.planet1} text="200+ projects" />
        <Planet src={Planet2Src} className={css.planet2} text="80 judges" />
        <Planet
          src={Planet3Src}
          className={css.planet3}
          text="$30,000 in prizes"
        />
        <Planet
          src={Planet4Src}
          className={css.planet4}
          text="“Getting to experience UCLA was pretty surreal”"
        />
        <Planet
          src={Planet5Src}
          className={css.planet5}
          text="“Hackathons are like a secondary education” "
        />
      </div>
    </div>
  );
};

const Planet: React.FC<{ src: string; className: string; text: string }> = ({
  src,
  className,
  text,
}) => {
  return (
    <div className={classNames(className, css.planetContainer)}>
      <img src={src} alt="planet" />
      <div className={css.funFact}>{text}</div>
    </div>
  );
};

const HackerGallerySection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const hackerGalleryPhotos = useMemo(
    () => getSheetData("Landing Page - Hacker Gallery", 6),
    [getSheetData]
  );
  return (
    <SectionContainer>
      <div className={css.gallery}>
        {hackerGalleryPhotos.map((photo) => (
          <div className={css.galleryItem} key={photo.id}>
            <GalleryPhoto id={photo.id} caption={photo.caption} />
          </div>
        ))}
      </div>
      <a href="/about-us#gallery">
        <ButtonNoBackground>view more {">"}</ButtonNoBackground>
      </a>
    </SectionContainer>
  );
};

const SponsorSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  return (
    <div className={css.cloudContainer}>
      <Cloud5 className={css.cloud5} />
      <Cloud6 className={css.cloud6} />
      <div
        className={css.sponsorBackground}
        style={{ backgroundImage: `url(${SponsorBackground})` }}
      >
        <div className={css.sponsorContainer}>
          <h2>Past Sponsors</h2>
          <SponsorLogos getSheetData={getSheetData} />
          <a href="/sponsor-us">
            <ButtonWithBackground>Sponsor Us</ButtonWithBackground>
          </a>
        </div>
      </div>
      <Cloud7 className={css.cloud7} />
      <Cloud8 className={css.cloud8} />
    </div>
  );
};

const BecomeAnOrganizerSection: React.FC<GalleryProps> = ({ getSheetData }) => {
  const groupPhoto = useMemo(
    () => getSheetData("Landing Page - Group Photo", 1),
    [getSheetData]
  );
  const organizerGalleryPhotos = useMemo(
    () => getSheetData("Landing Page - Organizer Gallery", 6),
    [getSheetData]
  );
  return (
    <div className={css.organizerContainer}>
      <div className={css.callToAction}>
        <div className={css.singlePhoto}>
          {groupPhoto[0] ? (
            <GalleryPhoto
              id={groupPhoto[0].id}
              caption={groupPhoto[0].caption}
            />
          ) : null}
        </div>
        <h3>Interested in becoming an organizer?</h3>
        <a href="https://forms.gle/P73gPDiTXa4avaCTA">
          <ButtonWithOutline>Apply Now!</ButtonWithOutline>
        </a>
      </div>
      <div className={css.gallery} id="gallery">
        {organizerGalleryPhotos.map((photo) => (
          <div className={css.galleryItem} key={photo.id}>
            <GalleryPhoto id={photo.id} caption={photo.caption} />
          </div>
        ))}
      </div>
    </div>
  );
};
