import "./PastDevposts.scss";
import { useState, useEffect } from "react";
import waxingCrescent from "../static/sponsor/waxingCrescent.svg";
import firstQuarter from "../static/sponsor/firstQuarter.svg";
import waxingGibbous from "../static/sponsor/waxingGibbous.svg";
import fullMoon from "../static/sponsor/fullMoon.svg";
import waningGibbous from "../static/sponsor/waningGibbous.svg";
import thirdQuarter from "../static/sponsor/thirdQuarter.svg";
import waningCrescent from "../static/sponsor/waningCrescent.svg";

const carouselItems = [
  { id: 1, src: waxingCrescent, link: "https://la-hacks-2020.devpost.com/", year: "2020" },
  { id: 2, src: firstQuarter, link: "https://la-hacks.devpost.com/", year: "2019" },
  { id: 3, src: waxingGibbous, link: "https://la-hacks-2018.devpost.com/", year: "2018" },
  { id: 4, src: fullMoon, link: "https://la-hacks-2024.devpost.com/", year: "2024" },
  { id: 5, src: waningGibbous, link: "https://la-hacks-2023.devpost.com/", year: "2023" },
  { id: 6, src: thirdQuarter, link: "https://la-hacks-2022.devpost.com/", year: "2022" },
  { id: 7, src: waningCrescent, link: "https://la-hacks-2021.devpost.com/", year: "2021" },
];

export const PastDevposts = () => {
  const [items, setItems] = useState(carouselItems);
  const [visibleItems, setVisibleItems] = useState(3); // Default to 3 visible items at a time

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 393) {
        setVisibleItems(3); // Show 3 items on small screens
      }
      else if (width <= 556) {
        setVisibleItems(4); // Show 4 items on small screens
      } else if (width <= 800) {
        setVisibleItems(5); // Show 5 items on medium screens
      } else {
        setVisibleItems(7); // Show all items on larger screens
      }
    };

    // Set initial visible items based on the current window size
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const currentIndex = Math.floor(visibleItems / 2); // Adjust center based on visible items

  const handlePrevious = () => {
    const newItems = [items[items.length - 1], ...items.slice(0, items.length - 1)];
    setItems(newItems);
  };

  const handleNext = () => {
    const newItems = [...items.slice(1), items[0]];
    setItems(newItems);
  };

  return (
    <div className="pastDevpostsContainer">
      <h2>Devpost archive</h2>
      <div className="carousel-container">
        <button className="carousel-button" onClick={handlePrevious}>
          ‹
        </button>
        <div className="carousel">
          <div className="carousel-items">
            {items.slice(0, visibleItems).map((item, index) => (
              <a
                key={item.id}
                href={item.link}
                className={`carousel-item ${index === currentIndex ? "active" : ""}`}
              >
                <img src={item.src} alt={`Carousel Item ${item.id}`} />
                <div className="carousel-label">{item.year}</div>
              </a>
            ))}
          </div>
        </div>
        <button className="carousel-button" onClick={handleNext}>
          ›
        </button>
      </div>
    </div>
  );
};
